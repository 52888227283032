import React, { useState, useEffect } from "react";

import {
  Grid,
  Container,
  Typography,
  Button,
  Paper,
  makeStyles,
  Card,
} from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

import { useLocation } from "react-router-dom";

import { Tab, Tabs } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";

import TextFieldGroup from "../../reusableComponents/TextFieldGroup";
import CustomizedDialogs from "../../reusableComponents/DialogModal";
import UserProgressReport from "./UserProgressReport";

import { USER_TOKEN } from "../../redux/actions/types";
import { connect, useDispatch } from "react-redux";
import {
  getProgramCourses,
  getProgramTopics,
  getProgramInfo,
} from "../../redux/actions/programActions";
import { getProgramScheduleList } from "../../redux/actions/scheduleActions";
import { checkOdooAccess } from "../../redux/actions/odooActions";
import ProgramSchedule from "../Admin_View/SchedulingList/ProgramSchedule";
import { postUserAccessLog } from "../../redux/actions/authActions";

import { $themeButton } from "../../reusableComponents/Colors";

import TaskList from "./UserTaskList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#fdfdff",
    width: "100%",
    padding: "0px",
    "& .MuiTypography-body1": {
      fontSize: "200px",
    },
  },

  title: {
    fontSize: 16,
    fontWeight: 600,
  },

  button: {
    fontWeight: 600,
    color: "#0F7C90",
    display: "flex",
    width: "100%",
    height: 90,
    justifyContent: "center",
    alignItems: "center",
  },

  tabs: {
    backgroundColor: "#FFFFFF",
    color: "black",
    "& @media only screen and (max-width: 600px)": {
      fontSize: "1px",
    },
    "& .MuiTypography-body1": {
      fontSize: "200px",
    },
  },
  tabList: {
    "& .MuiTabs-indicator": {
      display: "none",
      backgroundColor: "#0F7C90",
    },
  },
}));

const LearnerCourse = ({
  getProgramCourses,
  getProgramTopics,
  auth,
  match,
  programs,
  getProgramScheduleList,
  getProgramInfo,
  schedules,
  postUserAccessLog,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("1");
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const { programId } = match.params;
  const { programCourses, programTopics, programInfo } = programs;
  const { programSchedule } = schedules;
  const { user, isAuthenticated } = auth;

  const location = useLocation();
  // console.log("useLocation123",location)

  var tabVisibility = document.visibilityState;

  const [timeInterval, setTimeInterval] = useState(0);

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 60000);

  useEffect(() => {
    if (isAuthenticated && tabVisibility === "visible") {
      const data = {
        pageName: "Program Dashboard",
        recordedDate: new Date(),
        programId: programId,
      };
      postUserAccessLog(data);
    }
    // console.log("Individual Course Useage , Date", new Date(),"UserId :", user._id,"ProgramId :", programId,"Location : Program Dashboard");
  }, [timeInterval, tabVisibility]);

  useEffect(() => {
    getProgramCourses(programId);
    getProgramTopics(programId);
    getProgramInfo(programId);
    document.title = `Ibridge - Program Courses`;
  }, []);

  useEffect(() => {
    if (value === "4") {
      getProgramScheduleList(programId);
    }
  }, [value]);

  const handleChange = (event, newvalue) => {
    setValue(newvalue);
  };

  const handleIlearn = (course) => {
    window.open(
      `${process.env.REACT_APP_ILEARN_URL}/ibridge/${USER_TOKEN()}/${
        course._id
      }`,
      "_blank"
    );
  };

  // const handleICapability = (test) => {
  //   // return (
  //   //   (window.location.href = `${
  //   //     process.env.REACT_APP_CAPABILITY_URL
  //   //   }/menu/ibridge/${USER_TOKEN()}/test/${test.id}/${test.name}/${
  //   //     test.acronym
  //   //   }/${programId}`),
  //   //   "_blank"
  //   // );

  //   window.open(
  //     `${
  //       process.env.REACT_APP_CAPABILITY_URL
  //     }/menu/ibridge/${USER_TOKEN()}/test/${test.id}/${test.name}/${
  //       test.acronym
  //     }/${programId}`,
  //     "_blank"
  //   );
  // };

  const handleOdoo = () => {
    dispatch(checkOdooAccess());
    // window.open(`http://35.165.94.197:8069/web/login`, "_blank");
    // return (window.location.href = "http://35.165.94.197:8069");
  };

  const handleICapabilityResult = () => {
    window.open(
      `${
        process.env.REACT_APP_CAPABILITY_URL
      }/menu/ibridgeResults/${USER_TOKEN()}`,
      "_blank"
    );
  };

  const handleIProductivity = () => {
    window.open(
      `${
        process.env.REACT_APP_CAPABILITY_URL
      }/menu/ibridgeUserDashboard/${USER_TOKEN()}?tabIndex=1`,
      "_blank"
    );
  };

  const handleICapability = () => {
    window.open(
      `${
        process.env.REACT_APP_CAPABILITY_URL
      }/menu/ibridgeUserDashboard/${USER_TOKEN()}?tabIndex=0`,
      "_blank"
    );
  };

  const handleDebugging = () => {
    window.open(
      `${
        process.env.REACT_APP_CAPABILITY_URL
      }/menu/ibridgeUserDashboard/${USER_TOKEN()}?tabIndex=2`,
      "_blank"
    );
  };

  // handleICapabilityResult

  const eventData = programSchedule?.map((schedule) => {
    const {
      _id,
      name,
      description,
      scheduleEndDate,
      scheduleStartDate,
      programBatch,
      completed,
      taskType,
    } = schedule;
    return {
      title: `${name}\n${description}`,
      allDay: false,
      start: new Date(scheduleStartDate),
      end: new Date(scheduleEndDate),
      schedule: _id,
      programBatch: programBatch,
      userId: user?._id,
      completed: completed,
      programId,
      taskType,
      description,
    };
  });

  return (
    <Container style={{ padding: "0", margin: "0", maxWidth: "100%" }}>
      {/* <Paper elevation={0} square className={classes.root}> */}
      {/* <div className={classes.pageContent}> */}

      <TabContext value={value}>
        <AppBar position="static" className={classes.tabs}>
          <TabList onChange={handleChange} indicatorColor="primary">
            <Tab
              label="Courses"
              value="1"
              classes={{
                selected: classes.selected1,
              }}
              // className={classes.tabs}
            />
            <Tab
              label="Capability Assessment"
              value="2"
              classes={{
                selected: classes.selected1,
              }}
            />
            {/* <Tab
              label="Q&A"
              value="3"
              classes={{
                selected: classes.selected1,
              }}
            /> */}
            <Tab
              label="Program Schedule"
              value="4"
              classes={{
                selected: classes.selected1,
              }}
            />

            <Tab
              label="User Task"
              value="6"
              classes={{
                selected: classes.selected1,
              }}
            />

            {programInfo?.odooAccess ? (
              <Tab
                label="Odoo Access"
                value="5"
                classes={{
                  selected: classes.selected1,
                }}
              />
            ) : (
              <></>
            )}
          </TabList>
        </AppBar>

        <TabPanel value="1">
          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid item xs={10} md={2}>
              <Typography
                variant="h6"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                programCourses.length > 0 &&
                programCourses[0].programBatch
                  ? `Batch : ${
                      programCourses &&
                      programCourses.length > 0 &&
                      programCourses[0].programBatch
                    }`
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={10} md={8} align="center">
              <Typography
                variant="h5"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                  programCourses.length > 0 &&
                  programCourses[0].programName}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2} align="center">
              <Button
                variant="contained"
                style={{
                  marginLeft: "2%",
                  padding: "8px",
                  textTransform: "none",
                  background: $themeButton,
                  background: $themeButton,
                }}
                onClick={() => setOpen(true)}
              >
                View Report
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid
              items
              xs={4}
              md={3}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography>Course Name</Typography>
            </Grid>
            {/* <Grid
              items
              xs={4}
              md={4}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography>Course Completion</Typography>
            </Grid> */}
            <Grid
              items
              xs={8}
              md={9}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography>Course content</Typography>
            </Grid>
            {/* <Grid items xs={0} md={2}></Grid> */}

            {programCourses &&
            programCourses.length > 0 &&
            programCourses[0].name ? (
              programCourses?.map((item) => (
                <>
                  <Grid items xs={4} md={3} style={{ paddingTop: "2%" }}>
                    <Typography>{item.name}</Typography>
                    <LinearProgress
                      value={item?.percentage}
                      className="progressBar"
                      style={{ width: "60%" }}
                      variant="determinate"
                    />
                  </Grid>
                  {/* <Grid items xs={4} md={4} style={{ paddingTop: "2%" }}>
                    <Typography>
                      <LinearProgress
                        value={item?.percentage}
                        className="progressBar"
                        style={{ width: "60%" }}
                        variant="determinate"
                      />
                    </Typography>
                  </Grid> */}
                  <Grid items xs={8} md={9} style={{ paddingTop: "2%" }}>
                    <Button
                      buttonStyle="formSubmit"
                      type="submit"
                      className="formSubmit"
                      name="Sign In"
                      variant="contained"
                      onClick={() => handleIlearn(item)}
                      // color="#003399"
                      style={{ background: $themeButton, color: "white" }}
                    >
                      View Course Content
                    </Button>
                  </Grid>
                </>
              ))
            ) : (
              <Grid items xs={12} style={{ paddingTop: "2%" }}>
                <Typography>No Courses Available</Typography>
              </Grid>
            )}
            {/* <Grid items xs={4} md={2}></Grid>*/}
          </Grid>
        </TabPanel>

        <TabPanel value="2">
          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid item xs={10} md={2}>
              <Typography
                variant="h6"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                programCourses.length > 0 &&
                programCourses[0].programBatch
                  ? `Batch : ${
                      programCourses &&
                      programCourses.length > 0 &&
                      programCourses[0].programBatch
                    }`
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={10} md={8} align="center">
              <Typography
                variant="h5"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                  programCourses.length > 0 &&
                  programCourses[0].programName}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2} align="center">
              <Button
                variant="contained"
                style={{
                  marginLeft: "2%",
                  padding: "8px",
                  textTransform: "none",
                  background: $themeButton,
                  background: $themeButton,
                }}
                onClick={() => setOpen(true)}
              >
                View Report
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            {/* <Grid item xs={12} md={12} align="Left">
              <Button
                variant="contained"
                style={{
                  marginLeft: "0%",
                  padding: "8px",
                  textTransform: "none",
                  background: $themeButton,
                  background: $themeButton,
                }}
                onClick={() => handleICapabilityResult()}
              >
                iCapability
              </Button>
            </Grid> */}
            {/* <Grid
              item
              xs={6}
              md={3}
              style={{ color: "#003399", fontWeight: "600", marginTop: "1%" }}
            >
              <Typography className="TestColumnHeading">Topic name</Typography>
            </Grid> */}
            {/* <Grid
              items
              xs={3}
              md={2}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography className="TestColumnHeading">Test status</Typography>
            </Grid> */}
            {/* <Grid
              items
              xs={3}
              md={2}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography className="TestColumnHeading">
                Marks in Percentage
              </Typography>
            </Grid> */}
            <Grid
              item
              xs={6}
              md={4}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography className="TestColumnHeading">
                Connect To Test
              </Typography>

              <Button
                buttonStyle="formSubmit"
                type="submit"
                className="formSubmit"
                name="Sign In"
                variant="contained"
                onClick={() => handleICapability()}
                // color="#003399"
                style={{
                  background: $themeButton,
                  color: "white",
                  marginTop: "2%",
                }}
              >
                {`Start iCapability Assessment`}
              </Button>
            </Grid>
            {/* <Grid
              item
              xs={6}
              md={4}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography className="TestColumnHeading">
                Connect To Productivity
              </Typography>

              <Button
                buttonStyle="formSubmit"
                type="submit"
                className="formSubmit"
                name="Sign In"
                variant="contained"
                onClick={() => handleIProductivity()}
                // color="#003399"
                style={{
                  background: $themeButton,
                  color: "white",
                  marginTop: "2%",
                }}
              >
                {`Start Productivity Coding`}
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
              md={4}
              style={{ color: "#003399", fontWeight: "600" }}
            >
              <Typography className="TestColumnHeading">
                Connect To Debugging
              </Typography>

              <Button
                buttonStyle="formSubmit"
                type="submit"
                className="formSubmit"
                name="Sign In"
                variant="contained"
                onClick={() => handleDebugging()}
                // color="#003399"
                style={{
                  background: $themeButton,
                  color: "white",
                  marginTop: "2%",
                }}
              >
                {`Start Debugging`}
              </Button>
            </Grid> */}

            {/* {programTopics?.length > 0 && programTopics[0].name ? (
              <Grid container>
                <Grid item xs={6} md={7}>
                  {programTopics?.map((item) => (
                    <>
                      <Grid container>
                        <Grid items xs={6} md={4} style={{ paddingTop: "2%" }}>
                          <Typography>{item.name}</Typography>
                        </Grid>
                        <Grid items xs={6} md={2} style={{ paddingTop: "2%" }}>
                    <Typography>Test Taken</Typography>
                  </Grid>
                  <Grid items xs={6} md={2} style={{ paddingTop: "2%" }}>
                    <Typography>60%</Typography>
                  </Grid>
                        <Grid items xs={6} md={8} style={{ paddingTop: "2%" }}>
                          <Button
                            buttonStyle="formSubmit"
                            type="submit"
                            className="formSubmit"
                            name="Sign In"
                            variant="contained"
                            onClick={() => handleICapability(item)}
                            // color="#003399"
                            style={{ background: $themeButton, color: "white" }}
                          >
                            {`Start ${item.name} Assessment`}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  ))}
                </Grid>

                <Grid items xs={6} md={5} style={{ paddingTop: "2%" }}>
                  <Button
                    buttonStyle="formSubmit"
                    type="submit"
                    className="formSubmit"
                    name="Sign In"
                    variant="contained"
                    onClick={() => handleIProductivity()}
                    // color="#003399"
                    style={{ background: $themeButton, color: "white" }}
                  >
                    {`Start Productivity Coding`}
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid
                items
                xs={12}
                style={{ paddingTop: "2%", color: "red" }}
                variant="h5"
                align="center"
              >
                <Typography>No Topics Available</Typography>
              </Grid>
            )} */}
          </Grid>
        </TabPanel>

        <TabPanel value="3">
          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid item xs={10} md={2}>
              <Typography
                variant="h6"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                programCourses.length > 0 &&
                programCourses[0].programBatch
                  ? `Batch : ${
                      programCourses &&
                      programCourses.length > 0 &&
                      programCourses[0].programBatch
                    }`
                  : ""}
                {console.log(
                  "batchData,",
                  programCourses &&
                    programCourses.length > 0 &&
                    programCourses[0]._id
                )}
              </Typography>
            </Grid>
            <Grid item xs={10} md={8} align="center">
              <Typography
                variant="h5"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                  programCourses.length > 0 &&
                  programCourses[0].programName}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2} align="center">
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "2%",
                  padding: "8px",
                  textTransform: "none",
                }}
                onClick={() => setOpen(true)}
              >
                View Report
              </Button>
            </Grid>
          </Grid>

          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid
              items
              xs={12}
              md={12}
              style={{ paddingTop: "2%", paddingLeft: "2%" }}
            >
              <Typography>Ask your Questions</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Card container>
                <Card>
                  <TextFieldGroup
                    // label="Reason for counselling"
                    type="text"
                    name="reason"
                    multiline
                    rows={4}
                    // onChange={formik.handleChange}
                    // onBlur={formik.handleBlur}
                    // value={formik.values.reason}
                    // errors={
                    //   formik.touched.reason && formik.errors.reason
                    //     ? formik.errors.reason
                    //     : null
                    // }
                  />
                </Card>
              </Card>
            </Grid>

            <Grid item xs={12} md={7} align="right">
              <Button
                buttonStyle="formSubmit"
                type="submit"
                className="formSubmit"
                name="Sign In"
                variant="contained"
                onClick={handleICapability}
                style={{ background: "#003399", color: "white" }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value="4">
          <Grid container style={{ margin: "2%", marginTop: "1%" }}>
            <Grid item xs={10} md={2}>
              <Typography
                variant="h6"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                programCourses.length > 0 &&
                programCourses[0].programBatch
                  ? `Batch : ${
                      programCourses &&
                      programCourses.length > 0 &&
                      programCourses[0].programBatch
                    }`
                  : ""}
              </Typography>
            </Grid>
            <Grid item xs={10} md={8} align="center">
              <Typography
                variant="h5"
                style={{ color: "#003399", marginBottom: "10px" }}
              >
                {programCourses &&
                  programCourses.length > 0 &&
                  programCourses[0].programName}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2} align="center">
              <Button
                variant="contained"
                style={{
                  marginLeft: "2%",
                  padding: "8px",
                  textTransform: "none",
                  background: $themeButton,
                }}
                onClick={() => setOpen(true)}
              >
                View Report
              </Button>
            </Grid>
          </Grid>

          {/* <Grid container style={{ margin: "2%", marginTop: "1%" }}> */}
          <Grid
            items
            xs={12}
            md={12}
            style={{ paddingTop: "2%", paddingLeft: "2%" }}
          >
            {/* { console.log("LearnerCourse",eventData)} */}
            <ProgramSchedule eventData={eventData} />
          </Grid>
        </TabPanel>

        <TabPanel value="6">
          <TaskList
            programId={programId}
            batchId={
              programCourses &&
              programCourses.length > 0 &&
              programCourses[0].programBatchId
            }
          />
        </TabPanel>

        {programInfo?.odooAccess && (
          <TabPanel value="5">
            <Grid container style={{ margin: "2%", marginTop: "1%" }}>
              <Grid item xs={10} md={2}>
                <Typography
                  variant="h6"
                  style={{ color: "#003399", marginBottom: "10px" }}
                >
                  {programCourses &&
                  programCourses.length > 0 &&
                  programCourses[0].programBatch
                    ? `Batch : ${
                        programCourses &&
                        programCourses.length > 0 &&
                        programCourses[0].programBatch
                      }`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={10} md={8} align="center">
                <Typography
                  variant="h5"
                  style={{ color: "#003399", marginBottom: "10px" }}
                >
                  {programCourses &&
                    programCourses.length > 0 &&
                    programCourses[0].programName}
                </Typography>
              </Grid>
              <Grid item xs={2} md={2} align="center">
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "2%",
                    padding: "8px",
                    textTransform: "none",
                    background: $themeButton,
                  }}
                  onClick={() => setOpen(true)}
                >
                  View Report
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography>
                Click the button to access Odoo{" "}
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "2%",
                    padding: "8px",
                    textTransform: "none",
                    background: $themeButton,
                  }}
                  onClick={() => handleOdoo()}
                >
                  Odoo Access
                </Button>
              </Typography>
            </Grid>
          </TabPanel>
        )}
      </TabContext>
      {/* </div> */}
      {/* </Paper> */}

      <CustomizedDialogs
        title={
          <Grid container>
            <Grid item xs={6} md={6} style={{ backgroundColor: "white" }}>
              <Grid container>
                <Grid item xs={2} md={2}>
                  <Avatar style={{ height: "70px", width: "70px" }} />
                </Grid>

                <Grid item xs={10} md={10}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Typography>
                        {`${user?.firstName}${user?.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography>
                        {/* {course?.name}  */}
                        {programCourses ? programCourses[0]?.programName : ""}
                        {/* {console.log("programCourses", programCourses)} */}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography>
                        {/* {`${course?.programStartDate} - ${course?.programEndDate}`} */}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              align="right"
              style={{ paddingTop: "2%", paddingRight: "-10%" }}
            >
              {/* <Button
                        variant="contained" 
                        color="primary"
                        style={{height:"40px",width:"140px",marginRight:"-200px"}}
                    >
                        Send
                        <SendIcon style={{padding:"1%",paddingLeft:"4%"}}/>
                    </Button> */}
            </Grid>
          </Grid>
        }
        children={
          <UserProgressReport course={programId} learnerInfo={user?._id} />
        }
        openPopup={open}
        setOpenPopup={setOpen}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  programs: state.programs,
  schedules: state.schedules,
});

const mapDispatchToProps = {
  getProgramCourses,
  getProgramTopics,
  getProgramScheduleList,
  postUserAccessLog,
  getProgramInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LearnerCourse);
