import React, { useEffect } from "react";

const WelcomeRedirectComponent = () => {
  useEffect(() => {
    // Redirect to the WordPress site
    // window.location.replace("https://ibridge360.com/");
    window.location.replace(`${process.env.IBRIDGE_APP_URL}`);
    // IBRIDGE_APP_URL
  }, []); // The empty array ensures this runs only once when the component is mounted

  return null; // Since we are redirecting, no need to render anything
};

export default WelcomeRedirectComponent;
